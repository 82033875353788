import { Vector4 } from 'three';
import fragmentShader from './fragmentShader';
import vertexShader from './vertexShader';

function CubemapShaderWorldPos() {
  this.fragmentShader = fragmentShader;
  this.vertexShader = vertexShader;
  this.uniforms = {
    tEquirect0: { value: null },
    tEquirect1: { value: null },
    textureConfig0: { value: new Vector4() },
    textureConfig1: { value: new Vector4() },
    time: { value: 0 },
  };
}

export default CubemapShaderWorldPos;
