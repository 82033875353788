import React from 'react';
import Launcher from 'component/3dviewer/launcher';
import Viewer from './viewer';

export default class ThreeDViewer extends React.Component {
  constructor() {
    super();
    this.state = {
      isDataReady: false,
      isViewerReady: false,
    };
    this.onDataReady = this.onDataReady.bind(this);
    this.onViewerReady = this.onViewerReady.bind(this);
  }

  onDataReady() {
    this.setState({ isDataReady: true });
  }

  onViewerReady() {
    this.init();
  }

  init() {
    requestAnimationFrame(() => {
      this.setState({ isViewerReady: true });
    });
  }

  render() {
    const { isDataReady, isViewerReady } = this.state;
    return (
      <div className="3dviewer">
        {!(isDataReady && isViewerReady) ? (
          <Launcher onReady={this.onDataReady} />
        ) : null}
        {isDataReady === true ? <Viewer onReady={this.onViewerReady} /> : null}
      </div>
    );
  }
}
