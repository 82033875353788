import React from 'react';
import store from 'store/3dviewer';
import human from 'asset/image/human.svg';
import rectangle from 'asset/image/rectangle.svg';
import floorplan from 'asset/image/floorplan.svg';
import './css/BtnStyle.scss';

export default class ViewButton extends React.Component {
  constructor(props) {
    super(props);
    this.forceUpdate = this.forceUpdate.bind(this);
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('orientationchange', this.updateDimensions);
  }

  componentDidMount() {
    store.subscribe(this.forceUpdate); // add moniter changes from store and set listener
  }

  render() {
    const maskStyle = {
      transition: 'transform 0.5s',
    };
    const { viewButton } = store.getState();
    const { goDown, goUp, toOrthographics, viewState } = viewButton;
    switch (viewState) {
      case 'DOWN':
        maskStyle.transform = 'translate(0%)';
        break;
      case 'TOP':
        maskStyle.transform = 'translate(100%)';
        break;
      case 'FLOORPLAN':
        maskStyle.transform = 'translate(200%)';
        break;
      default:
        maskStyle.transform = 'translate(0%)';
    }

    return (
      <div className="viweBtn-container">
        <div className="buttonMask" style={maskStyle}>
          {' '}
        </div>
        <button
          className="viewButton"
          type="button"
          hidden={false}
          onClick={goDown}
        >
          <img src={human} alt="human" />
          <i className="icon-walk-view"> </i>
          <span className="buttonText"> &nbsp;行走視角 </span>
        </button>
        <button
          className="viewButton"
          type="button"
          hidden={false}
          onClick={goUp}
        >
          <img src={rectangle} alt="rectangle" />
          <i className="icon-3d-view"> </i>
          <span className="buttonText">&nbsp;3D視角 </span>
        </button>
        <button
          className="viewButton"
          type="button"
          hidden={false}
          onClick={toOrthographics}
        >
          <img src={floorplan} alt="floorplan" />
          <i className="icon-orthogonal-view"> </i>
          <span className="buttonText">&nbsp;格局圖 </span>
        </button>
      </div>
    );
  }
}
