import store from 'store/3dviewer/index';

export default {
  getCubemapUrl1x6(id) {
    return store.getState().cubemapUrls1x6[id];
  },
  getCubemapUrl(id) {
    return store.getState().cubemapUrls[id];
  },
};
