import MouseControls from './mouse-control';
import MouseHintController from './mouse-hint-points-controls';
import CanvasEvents from './canvas-events';

function MouseEvents(viewerCameraController) {
  const mouseControls = new MouseControls(viewerCameraController);
  function init(
    movingController,
    allHotspotsMeshArray,
    allRoomsMeshArray,
    allDoorsMeshArray,
    hotspots,
    canvas
  ) {
    this.mouseHintController = new MouseHintController(
      viewerCameraController,
      allHotspotsMeshArray
    );
    this.mouseHintController.allHotspots = allHotspotsMeshArray;
    const canvasEvents = new CanvasEvents(
      mouseControls,
      movingController,
      this.mouseHintController,
      viewerCameraController,
      allHotspotsMeshArray,
      allRoomsMeshArray,
      allDoorsMeshArray,
      hotspots
    );
    canvasEvents.attach(canvas);
  }
  this.init = init;
}

export default MouseEvents;
