import * as THREE from 'three';
import StoreGetters from 'store/3dviewer/store-getters';
import store from 'store/3dviewer';
import actions from 'store/3dviewer/actions';
import { loadTexture } from '../loaders/texture-loader';
import { loadCubeTexture } from '../loaders/cubemap-texture-loader';
import RenderOrder from '../object-manager/mat-manager';
import moveCamera2InitialPose from './clips/Hotspot2Top';
import top2hotspot from './clips/Top2Hotspot';
import hotspot2hotspot from './clips/Hotspot2Hotspot';
import RotateCameraAround from './clips/RotateCameraAround';
import TransitionCameraFov from './clips/FloorPlan2Topview';
import Move2TopCenter from './clips/Move2FloorPlan';
import Move2FpFromOrtho from './clips/Move2FpFromFloorPlan';
import TransitionFloorplanAlpha from './clips/TransitionFloorplanAlpha';
import ObjectManager from '../object-manager';
import carrier from '../carrier';
import RecordHintPointController from './RecordHintPointController';

function getTextureInfo(targetHospot) {
  const { rooms, roomGroup } = ObjectManager.getAll();
  const room = rooms[targetHospot.mainRoomId];

  const hotSpotWorldPos = new THREE.Vector3();
  targetHospot.mesh.getWorldPosition(hotSpotWorldPos);
  const textureConfig = {
    textureId: targetHospot.hotspotId,
    x: hotSpotWorldPos.x,
    y: hotSpotWorldPos.y + targetHospot.cameraHeight * roomGroup.scale.y,
    z: hotSpotWorldPos.z,
    rotation:
      room.mesh.rotation.y +
      THREE.Math.degToRad(targetHospot.rotateY) +
      targetHospot.manhattanRotation,
  };

  return textureConfig;
}
function UpdateViewBtn(to3D, toTop, toOrtho, siwperClick, state) {
  store.dispatch(
    actions.setViewButton({
      goDown: to3D,
      goUp: toTop,
      toOrthographics: toOrtho,
      goDependOnState: siwperClick,
      viewState: state,
    })
  );
}
const STATE = {
  FPVIEW: 'DOWN',
  TOPVIEW: 'TOP',
  FLOORPLAN: 'FLOORPLAN',
  isMoving: false,
};
export { STATE };
// 用來控制hotspot間的移動及3D/CubeMap切換
export default class MovingController {
  constructor() {
    this.sourceHotSpot = null;
    // 現在攝影機所在hotspot null表示在在topview
    this.destinationHotspot = null;
    // 上一次回到topView前的站點hotspot object (並非在行走視角的起始位置)
    this.lastDownViewHotspot = null;
    // 判斷攝影機狀態
    this.nowIsTopView = true; // 判斷,現在攝影機是否移動回topView

    this.firstMove2MainRoom = true; // 進入網頁第一次走動開關

    // 紀錄點提示point(紀錄上一次DownView hotspot)
    this.recordHintPointController = new RecordHintPointController();
    this.recordHintPointController.initRecordHintPoint();

    this.viewerCameraController = null;
    this.mouseHintController = null;

    this.BackToStartPos = this.BackToStartPos.bind(this);
    this.GoToLastDownViewPos = this.GoToLastDownViewPos.bind(this);
    this.GoOrthographicsView = this.GoOrthographicsView.bind(this);
    this.transition2Perspective = this.transition2Perspective.bind(this);
    this.hotspot2AnyHotspot = this.hotspot2AnyHotspot.bind(this);
    this.top2HotSpot = this.top2HotSpot.bind(this);

    this.state = null;
    this.setState = state => {
      const { mesh } = this.fpviewMesh;
      switch (state) {
        case STATE.FPVIEW:
          UpdateViewBtn(
            () => {},
            this.BackToStartPos,
            this.GoOrthographicsView,
            id => {
              const { hotspots } = ObjectManager.getAll();
              if (hotspots[id] !== undefined)
                this.hotspot2AnyHotspot(hotspots[id][id]);
            },
            state
          );
          if (mesh.parent === null) this.parent.add(mesh);
          break;
        case STATE.TOPVIEW:
          UpdateViewBtn(
            this.GoToLastDownViewPos,
            () => {},
            this.GoOrthographicsView,
            id => {
              const { hotspots } = ObjectManager.getAll();
              if (hotspots[id] !== undefined)
                this.top2HotSpot(hotspots[id][id]);
            },
            state
          );
          if (mesh.parent !== null) this.parent.remove(mesh);
          break;
        case STATE.FLOORPLAN:
          UpdateViewBtn(
            this.GoToLastDownViewPos,
            this.transition2Perspective,
            () => {},
            id => {
              const { hotspots } = ObjectManager.getAll();
              if (hotspots[id] !== undefined)
                this.top2HotSpot(hotspots[id][id]);
            },
            state
          );
          if (mesh.parent !== null) this.parent.remove(mesh);
          break;
        default:
          break;
      }
      this.state = state;
    };

    this.parent = null;
    this.fpviewMesh = null;
  }

  async GoOrthographicsView() {
    if (STATE.isMoving === true) return;

    STATE.isMoving = true;
    this.recordFPInfoWhenBackToTop();

    this.setState(STATE.FLOORPLAN);
    Move2TopCenter(this.viewerCameraController, 5).then(() => {
      STATE.isMoving = false;
      this.setState(STATE.FLOORPLAN);
    });
    TransitionFloorplanAlpha('fadeIn');
  }

  transition2Perspective() {
    if (STATE.isMoving === true) return;

    STATE.isMoving = true;
    this.setState(STATE.TOPVIEW);
    TransitionCameraFov(this.viewerCameraController, 75, 1).then(() => {
      STATE.isMoving = false;
    });
    TransitionFloorplanAlpha('fadeOut');
  }

  // THREE.Mesh都create完後設定movingController的資訊
  init(viewCamerControls, mouseHintController, parnet) {
    const { allRoomsMeshArray, mainroomHotspot } = ObjectManager.getAll();
    this.viewerCameraController = viewCamerControls;
    this.mouseHintController = mouseHintController;

    this.fpviewMesh = new carrier.TextureTransitionCarrier(allRoomsMeshArray);
    this.fpviewMesh.mesh.renderOrder = RenderOrder.fpMesh;
    this.fpviewMesh.mesh.onAfterRender = () => {
      viewCamerControls.renderer.clearDepth();
    };
    this.parent = parnet;
    this.setState(STATE.TOPVIEW);
    if (this.firstMove2MainRoom) {
      STATE.isMoving = true;
      RotateCameraAround(viewCamerControls).then(() => {
        STATE.isMoving = false;
        this.top2HotSpot(mainroomHotspot);
      });
    }
  }

  top2HotSpot(hotspot) {
    if (hotspot === this.destinationHotspot || STATE.isMoving === true) return;

    const DoProcess = srcIsOthographicCamera => {
      STATE.isMoving = true;

      // 將hotspot暫存
      this.sourceHotSpot = this.destinationHotspot;
      this.destinationHotspot = hotspot;

      this.recordHintPointController.setRecordHintPointVisible(false);

      // back high resolution use low first to prevent internet lost

      const cubemapUrls1x6 = StoreGetters.getCubemapUrl1x6(hotspot.hotspotId);

      loadTexture(cubemapUrls1x6).then(texture => {
        const config = getTextureInfo(hotspot);
        this.fpviewMesh.updateTexture(texture, config, true);
      });

      // send request to get high resoulution
      const cubemapUrls = StoreGetters.getCubemapUrl(hotspot.hotspotId);
      const loadTexturePromise = loadCubeTexture(cubemapUrls);

      // start animation
      const animationDone = [];

      if (srcIsOthographicCamera) {
        animationDone.push(TransitionFloorplanAlpha('fadeOut'));
        animationDone.push(
          Move2FpFromOrtho(this.viewerCameraController, hotspot)
        );
      } else {
        animationDone.push(
          top2hotspot(
            this.viewerCameraController,
            hotspot,
            srcIsOthographicCamera
          )
        );
      }

      this.setState(STATE.FPVIEW);
      const amine = Promise.all(animationDone).then(() => {
        STATE.isMoving = false;
        this.nowIsTopView = false;
        ObjectManager.setAllHotspotOpacity(0.4);
        ObjectManager.setAllMeshOpacity(0);
        this.setState(STATE.FPVIEW);
      });

      // start animation
      return Promise.all([loadTexturePromise, amine]).then(values => {
        if (STATE.isMoving === false) {
          const config = getTextureInfo(hotspot);
          this.fpviewMesh.updateTexture(values[0], config, false);
        }
      });
    };

    DoProcess(this.state === STATE.FLOORPLAN);
  }

  hotspot2Hotspot(hotspot, throughWallsetting = { enable: false }) {
    if (hotspot === this.destinationHotspot || STATE.isMoving === true) return;

    STATE.isMoving = true;

    // 將hotspot暫存
    this.sourceHotSpot = this.destinationHotspot;
    this.destinationHotspot = hotspot;

    this.recordHintPointController.setRecordHintPointVisible(false);

    // back high resolution use low first to prevent internet lost
    const cubemapUrls1x6 = StoreGetters.getCubemapUrl1x6(
      this.sourceHotSpot.hotspotId
    );

    loadTexture(cubemapUrls1x6).then(textre => {
      const config = getTextureInfo(this.sourceHotSpot);
      this.fpviewMesh.updateTexture(textre, config, true);

      const cubemapUrls1x6dst = StoreGetters.getCubemapUrl1x6(
        hotspot.hotspotId
      );
      loadTexture(cubemapUrls1x6dst).then(textre2 => {
        const config2 = getTextureInfo(hotspot);
        this.fpviewMesh.addAnimeTexture(textre2, config2, true);
      });
    });

    const cubemapUrls = StoreGetters.getCubemapUrl(hotspot.hotspotId);
    const loadTexturePromise = loadCubeTexture(cubemapUrls);
    const animationDone = hotspot2hotspot(
      this.viewerCameraController,
      this.fpviewMesh,
      hotspot,
      throughWallsetting
    ).then(() => {
      STATE.isMoving = false;
    });

    Promise.all([loadTexturePromise, animationDone]).then(values => {
      if (STATE.isMoving === false) {
        const config = getTextureInfo(hotspot);
        this.fpviewMesh.updateTexture(values[0], config, false);
      }
    });
  }

  /**
   * 按下home button觸發移動到topView
   */
  async BackToStartPos() {
    // 若在移動中or攝影機在topview 將無法點擊home鍵
    if (STATE.isMoving === true) return;

    STATE.isMoving = true;
    this.recordFPInfoWhenBackToTop();

    this.setState(STATE.TOPVIEW);

    moveCamera2InitialPose(this.viewerCameraController).then(() => {
      STATE.isMoving = false;
      this.setState(STATE.TOPVIEW);
    });
  }

  async recordFPInfoWhenBackToTop() {
    this.mouseHintController.MousePointVisible = false;
    ObjectManager.resetAllModelTexture();

    if (this.destinationHotspot != null) {
      this.lastDownViewHotspot = this.destinationHotspot;
      this.recordHintPointController.changeRecordHintPointPos(
        this.lastDownViewHotspot
      );
      this.destinationHotspot = null;
    }

    this.recordHintPointController.setRecordHintPointVisible(true);
    this.nowIsTopView = true;
  }

  hotspot2AnyHotspot(hotspot) {
    if (hotspot === this.destinationHotspot || STATE.isMoving === true) return;

    const { roomGroup } = ObjectManager.getAll();

    const currentPosition = new THREE.Vector3();
    hotspot.mesh.getWorldPosition(currentPosition);
    currentPosition.y += hotspot.cameraHeight * roomGroup.scale.y;

    const destinationPosition = new THREE.Vector3();
    this.destinationHotspot.mesh.getWorldPosition(destinationPosition);
    destinationPosition.y +=
      this.destinationHotspot.cameraHeight * roomGroup.scale.y;

    const cur2dst = new THREE.Vector3().subVectors(
      destinationPosition,
      currentPosition
    );

    const dst2cur = new THREE.Vector3().subVectors(
      currentPosition,
      destinationPosition
    );

    const distance = cur2dst.length();
    cur2dst.normalize();

    const cur2dstRaycaster = new THREE.Raycaster(
      currentPosition,
      cur2dst,
      0,
      distance
    );

    const intersectcur2dst = cur2dstRaycaster.intersectObject(
      this.fpviewMesh.mesh
    );

    const dst2curRaycaster = new THREE.Raycaster(
      destinationPosition,
      dst2cur,
      0,
      distance
    );

    const intersectdst2cur = dst2curRaycaster.intersectObject(
      this.fpviewMesh.mesh
    );

    if (intersectcur2dst.length === 0 && intersectdst2cur.length === 0) {
      this.hotspot2Hotspot(hotspot);
    } else {
      let blackOut = 0.05;
      let blackIn = 0.95;

      if (intersectcur2dst[0] !== undefined) {
        blackOut = intersectcur2dst[0].distance / 2 / distance;
      }

      if (intersectdst2cur[0] !== undefined) {
        blackIn = (distance - intersectdst2cur[0].distance / 2) / distance;
      }

      this.hotspot2Hotspot(hotspot, {
        enable: true,
        blackInterval: [blackOut, blackIn],
      });
    }
  }

  /**
   * 點擊walk button 觸發 回到上一次down view 位置
   */
  GoToLastDownViewPos() {
    if (
      this.lastDownViewHotspot === null ||
      this.lastDownViewHotspot === undefined ||
      this.nowIsTopView === false ||
      STATE.isMoving === true
    )
      return;
    this.top2HotSpot(this.lastDownViewHotspot);
  }
}
