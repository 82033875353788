import axios from 'axios';

const xhr = axios.create();

export default {
  load(url, headers, objectId, buildingId, modelType, onDownloadProgress) {
    const request = xhr.request({
      method: 'POST',
      url,
      onUploadProgress: onDownloadProgress,
      responseType: 'json',
      headers,
      data: {
        data: {
          buildingId,
          panoId: objectId,
          type: `${modelType}.obj`,
        },
      },
    });
    return request;
  },
};
