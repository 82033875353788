import React from 'react';
import store from 'store/3dviewer';
import { STATE } from 'services/3dviewer/moving-controls';
import FloorPlan2D from 'services/3dviewer/floorplan';
import './css/floorplan-canvas.scss';

export default class FloorPlanCanvas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPoped: false,
    };
    this.viewChange = this.viewChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.style = { display: 'none' };
    this.setCameraIcon = () => {};
    this.renderFloorPlan = () => {};
  }

  componentDidMount() {
    store.subscribe(this.viewChange);
    const data = store.getState().building.floorplane;
    const floorplan = new FloorPlan2D(this.canvas, data);
    this.setCameraIcon = floorplan.setCameraIcon;
    this.renderFloorPlan = floorplan.updateViewPort;
  }

  componentDidUpdate() {
    this.renderFloorPlan();
  }

  viewChange() {
    const { viewButton } = store.getState();
    const { viewState } = viewButton;

    if (viewState === STATE.FPVIEW && !STATE.isMoving) {
      this.setCameraIcon(true);
      this.style = {};
    } else {
      this.setCameraIcon(false);
      this.style = { display: 'none' };
    }
    this.forceUpdate();
  }

  handleClick() {
    const { isPoped } = this.state;
    if (isPoped) {
      this.setState({ isPoped: false });
    } else {
      this.setState({ isPoped: true });
    }
  }

  render() {
    // console.log('canvas renderer');
    const { isPoped } = this.state;

    return (
      <canvas
        id="FloorPlanContainer"
        className={` ${isPoped ? 'popedFloorPlan' : 'defaultFloorPlan'} `}
        ref={ref => {
          this.canvas = ref;
        }}
        onClick={this.handleClick}
        style={this.style}
      />
    );
  }
}
