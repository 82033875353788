import * as THREE from 'three';
import createAll from './creator';
import FloorPlanBounding, { setAllBoundingAlpha } from './floorplan-bounding';

let rooms = null;
let doors = null;
let hotspots = null;
let roomGroup = null;
let allTextures = null;
let wallBoundingGroup = null;
let doorBoundingGroup = null;
let floorplanAreaGroup = null;

let allRoomsMeshArray = [];
let allDoorsMeshArray = [];
let allHotspotsMeshArray = [];
let mainroomHotspot = null;

const raycaster = new THREE.Raycaster();
const mainRoomType = 'Livingroom';

function normalizeAllHotspotScale(iHotspots, factor) {
  Object.values(iHotspots).forEach(hotspotGroup => {
    Object.values(hotspotGroup).forEach(hotspot => {
      hotspot.normalizeScale(factor);
    });
  });
}

function normalizeGroupAndSetCenter(iRoomGroup) {
  const bbox = new THREE.Box3().setFromObject(iRoomGroup);
  const center = new THREE.Vector3();
  const size = new THREE.Vector3();

  bbox.getCenter(center);
  bbox.getSize(size);

  const maxScale = 1 / Math.max(size.x, size.y, size.z);

  iRoomGroup.position.set(
    -center.x * maxScale,
    -center.y * maxScale,
    -center.z * maxScale
  );
  iRoomGroup.scale.set(maxScale, maxScale, maxScale);

  iRoomGroup.updateWorldMatrix(true, true);
  return 1 / maxScale;
}
function normalizeAndSetCenter(iRoomGroup) {
  const hotspotScaleFactor = normalizeGroupAndSetCenter(iRoomGroup);
  // add normalize posy to all hotspots
  normalizeAllHotspotScale(hotspots, hotspotScaleFactor);
}

function switchTexture(mainRoomId, hotspotsId) {
  if (mainRoomId == null || hotspotsId == null) {
    console.error('mainRoomId or hotspotId is undefined');
    return;
  }
  const hotspot = hotspots[mainRoomId][hotspotsId];
  // const mahattanMatrix =
  const { rotateY, mesh } = hotspot;
  rooms[mainRoomId].setTexture(allTextures[hotspotsId], mesh.position, rotateY);
  doors[mainRoomId].setTexture(allTextures[hotspotsId], mesh.position, rotateY);
}
function switchTextureAsync(mainRoomId, hotspotsId) {
  return new Promise(resolve => {
    if (mainRoomId == null || hotspotsId == null) {
      console.error('mainRoomId or hotspotId is undefined');
      return;
    }
    const hotspot = hotspots[mainRoomId][hotspotsId];
    const { rotateY, mesh } = hotspot;
    rooms[mainRoomId].setTexture(
      allTextures[hotspotsId],
      mesh.position,
      rotateY
    );
    doors[mainRoomId].setTexture(
      allTextures[hotspotsId],
      mesh.position,
      rotateY
    );
    resolve();
  });
}

function resetAllModelTexture() {
  Object.keys(rooms).forEach(mainRoomId => {
    const room = rooms[mainRoomId];
    if (room.mainRoomId !== room.textureId) {
      switchTexture(mainRoomId, mainRoomId);
    }
  });
}
function setAllMeshOpacity(opacity) {
  Object.keys(rooms).forEach(mainRoomId => {
    const room = rooms[mainRoomId];
    const door = doors[mainRoomId];
    room.setOpacity(opacity);
    door.setOpacity(opacity);
  });
}
function setAllHotspotOpacity(opacity) {
  Object.keys(hotspots).forEach(mainRoomId => {
    Object.keys(hotspots[mainRoomId]).forEach(hotspotId => {
      hotspots[mainRoomId][hotspotId].setOpacity(opacity);
    });
  });
}
function initAllObjectMeshArray() {
  allRoomsMeshArray = Object.values(rooms).map(item => item.mesh);
  allDoorsMeshArray = Object.values(doors).map(item => item.mesh);
  Object.values(hotspots).forEach(hotspotGroup => {
    const singleRoomHotspotMesh = Object.values(hotspotGroup).map(
      item => item.mesh
    );
    allHotspotsMeshArray = allHotspotsMeshArray.concat(singleRoomHotspotMesh);
  });
}
function getMainroomHotspot(hierarchy) {
  const mainRoomIds = Object.keys(hierarchy);
  mainroomHotspot = hotspots[mainRoomIds[0]][mainRoomIds[0]];
  mainRoomIds.forEach(mainRoomId => {
    if (hierarchy[mainRoomId].info.type === mainRoomType)
      mainroomHotspot = hotspots[mainRoomId][mainRoomId];
  });
}
function calIntersectDoors(srcPos, dstPos) {
  const ray = new THREE.Vector3().subVectors(dstPos, srcPos);
  const moveDistance = ray.length();
  raycaster.set(srcPos, ray.normalize());

  let roomDoor = [];

  roomDoor = allRoomsMeshArray.concat(allDoorsMeshArray);
  const hotspotinteracts = raycaster.intersectObjects(roomDoor, true);

  const result = [];
  for (let i = 0; i < hotspotinteracts.length; i += 1) {
    if (hotspotinteracts[i].distance < moveDistance) {
      result.push({
        point: hotspotinteracts[i].point,
        mesh: hotspotinteracts[i].object,
      });
    }
  }

  return result;
}
async function init(hierarchy, roomModels, doorModels, labels, textures) {
  const { allRoomMesh, allRooms, allDoors, allHotspots } = await createAll(
    hierarchy,
    labels,
    roomModels,
    doorModels,
    textures
  );
  return new Promise(resolve => {
    rooms = allRooms;
    doors = allDoors;
    hotspots = allHotspots;
    allTextures = textures;
    normalizeAndSetCenter(allRoomMesh);
    initAllObjectMeshArray();
    getMainroomHotspot(hierarchy);
    roomGroup = allRoomMesh;
    doorBoundingGroup = FloorPlanBounding.createDoorBoundingGroup(doors);
    wallBoundingGroup = FloorPlanBounding.createWallBoundingGroup(rooms);
    floorplanAreaGroup = FloorPlanBounding.createRoomFloorArea(rooms);
    resolve({
      allRoomMesh,
      allRoomsMeshArray,
      allDoorsMeshArray,
      allHotspotsMeshArray,
      doorBoundingGroup,
      wallBoundingGroup,
      floorplanAreaGroup,
      hotspots,
    });
  });
}
function getAll() {
  return {
    rooms,
    doors,
    hotspots,
    allRoomsMeshArray,
    allDoorsMeshArray,
    allHotspotsMeshArray,
    mainroomHotspot,
    roomGroup,
    doorBoundingGroup,
    wallBoundingGroup,
    floorplanAreaGroup,
  };
}
export default {
  init,
  getAll,
  calIntersectDoors,
  switchTexture,
  resetAllModelTexture,
  setAllMeshOpacity,
  setAllHotspotOpacity,
  switchTextureAsync,
  setAllBoundingAlpha,
  normalizeGroupAndSetCenter,
};
