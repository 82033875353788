import axios from 'axios';
import ObjService from 'services/obj';

const dir = process.env.REACT_APP_3DVIWER_CDN;
const headers = {
  apiKey: 'AIzaSyCjgFBdfpToVv9bpRQDJ-73qfWBOaKQkPU',
  projectId: 'dviewer-824cb',
};
export default {
  fetchBuilding(buildingId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${dir}buildings`, { data: { buildingId } }, { headers })
        .then(res => {
          const result = { data: res.data.result };
          if (result.data === null || buildingId === '') {
            resolve({ res: null });
          } else {
            resolve(result);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchPanoramas(buildingId, onDownloadProgress) {
    const apiLink =
      'https://vrcam-dev-api.istaging.com/api/v2/buildings/openLink/';
    return new Promise((resolve, reject) => {
      axios
        .get(apiLink + buildingId, { onDownloadProgress })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
          console.error('Fetch Panorama error');
        });
    });
  },

  fetchModel(objectId, buildingId, modelType, onDownloadProgress) {
    return new Promise((resolve, reject) => {
      ObjService.load(
        `${dir}getObjFiles`,
        headers,
        objectId,
        buildingId,
        modelType,
        onDownloadProgress
      )
        .then(res => {
          resolve(res.data.result);
        })
        .catch(error => {
          console.error(`Fetch ${modelType} Error`);
          reject(error);
        });
    });
  },
};
