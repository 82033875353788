const RenderOrder = {
  tagLine: 20,
  fpMesh: 2,

  fadeOut: 10,

  room: 15,
  hotspot: 16,
  door: 17,

  hotspotRecord: 25,
  mouseHint: 26,

  floorPlan: 50,
};

export { RenderOrder as default };
