import axios from 'axios';

const xhr = axios.create();

export default {
  load(url, onDownloadProgress) {
    const request = xhr.request({
      method: 'GET',
      url,
      onDownloadProgress,
    });
    return request;
  },
};
