import * as THREE from 'three';
import RenderOrder from './mat-manager';

const wallBoundingGroup = new THREE.Group();
wallBoundingGroup.renderOrder = RenderOrder.floorPlan;
const wallBoundingPosY = 0.2; // wall bounding init height
const wallBoundingWidth = 0.05; // wall bounding init width
const wallBoundingColor = new THREE.Color('white'); // room bounding color

const doorBoundingGroup = new THREE.Group();
doorBoundingGroup.renderOrder = RenderOrder.floorPlan;
const doorBoundingPosY = 0.21; // door bounding init height
const doorBoundingWidth = 0.02; // door bounding init width
const doorBoundingColor = new THREE.Color(0x707070); // door bounding color

const roomFloorAreaGroup = new THREE.Group();
roomFloorAreaGroup.renderOrder = RenderOrder.floorPlan;
const roomFloorAreaInitColor = new THREE.Color('white'); // room floor area init color
const roomFloorAreaInitPosY = 0.2; // room floor group init height
const roomFloorAreaMaxAlpha = 0.3; // room floor group init alpha

/**
 * 創建單一door bounding
 * @param {*} pos door bounding center position
 * @param {*} length bounding length
 */
function createSingleDoorBounding(pos, length) {
  // 將長度設為length+singleWallWidth (解決轉角沒有連接問題)
  const geometry = new THREE.BoxGeometry(length, 0.01, doorBoundingWidth);
  const material = new THREE.MeshBasicMaterial({
    color: doorBoundingColor,
    transparent: true,
    opacity: 0,
    depthTest: true,
  });

  const singleBoundingDoor = new THREE.Mesh(geometry, material);
  singleBoundingDoor.name = 'DoorBounding';
  singleBoundingDoor.position.set(pos.x, 0, pos.y);

  return singleBoundingDoor;
}
/**
 * 創建單一wall bounding
 * @param {*} pos wall bounding center position
 * @param {*} length bounding length
 */
function createSingleWallBounding(pos, length) {
  // 將長度設為length+singleWallWidth (解決轉角沒有連接問題)
  const geometry = new THREE.BoxGeometry(
    length + wallBoundingWidth,
    0.01,
    wallBoundingWidth
  );
  const material = new THREE.MeshBasicMaterial({
    color: wallBoundingColor,
    transparent: true,
    opacity: 0,
    depthTest: true,
  });

  const singleBoundingWall = new THREE.Mesh(geometry, material);
  singleBoundingWall.name = 'WallBounding';
  singleBoundingWall.position.set(pos.x, 0, pos.y);

  return singleBoundingWall;
}
/**
 * 創建房間面積floorplan object
 * @param {*} allRoomModels
 */
function createRoomFloorArea(roomsCarriers) {
  Object.values(roomsCarriers).forEach(room => {
    const { mesh } = room;
    const model = mesh;
    const geometry = new THREE.Geometry();
    geometry.merge(
      new THREE.Geometry().fromBufferGeometry(model.geometry),
      model.matrixWorld
    );

    const material = new THREE.MeshBasicMaterial({
      color: roomFloorAreaInitColor,
      side: THREE.FrontSide,
      transparent: true,
      opacity: 0,
      depthTest: true,
    });

    const singleRoomFloorArea = new THREE.Mesh(geometry, material);
    singleRoomFloorArea.scale.set(1, 0.01, 1);
    roomFloorAreaGroup.add(singleRoomFloorArea);
  });
  // set position y
  roomFloorAreaGroup.position.setY(roomFloorAreaInitPosY);
  return roomFloorAreaGroup;
}
/**
 * 創建wall bounding group
 * @param {*} roomsCarriers
 */
function createWallBoundingGroup(roomsCarriers) {
  Object.values(roomsCarriers).forEach(room => {
    const { points, mesh } = room;

    for (let j = 0; j < points.length; j += 1) {
      const pointA = points[j];
      let pointB = null;

      // 兩兩point跑所有layout points
      if (j === points.length - 1) {
        [pointB] = points;
      } else {
        pointB = points[j + 1];
      }

      // 計算point距離來當bounding長度
      const length = pointA.distanceTo(pointB);
      let singleWall = null;

      // 計算pointA,B畫出來的線對應 positive x-axis angle 來定義是直線或橫線
      let angleDeg =
        (Math.atan2(pointB.y - pointA.y, pointB.x - pointA.x) * 180) / Math.PI;
      angleDeg = Math.abs(angleDeg);

      // 依據point x,y座標來定義 wall bounding位置
      if (angleDeg <= 45 || angleDeg >= 135) {
        const midX = (pointA.x + pointB.x) / 2;
        const centerPos = new THREE.Vector2(midX, pointA.y);
        singleWall = createSingleWallBounding(centerPos, length);
      } else {
        const midY = (pointA.y + pointB.y) / 2;
        const centerPos = new THREE.Vector2(pointA.x, midY);
        singleWall = createSingleWallBounding(centerPos, length);
        singleWall.rotateY(Math.PI / 2);
      }

      // wall bounding 加到 room model children 繼承position scale rotation
      mesh.add(singleWall);
      singleWall.position.set(singleWall.position.x, 0, singleWall.position.z);

      // re-attach to group parent (change parent but keep position, scale, rotation)
      wallBoundingGroup.attach(singleWall);
      singleWall.position.setY(wallBoundingPosY);
    }
  });
  return wallBoundingGroup;
}

/**
 * 創建door bounding group
 * @param {*} doorsCarriers
 */
function createDoorBoundingGroup(doorsCarriers) {
  Object.values(doorsCarriers).forEach(door => {
    const { mesh } = door;
    const { length } = mesh.geometry.attributes.position.array;
    const positionArray = mesh.geometry.attributes.position.array;
    for (let i = 0; i < length; i += 18) {
      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        'position',
        new THREE.BufferAttribute(positionArray.subarray(i, i + 18), 3)
      );

      // 計算bounding box 取max, min座標
      geometry.computeBoundingBox();
      const pointA = new THREE.Vector2(
        geometry.boundingBox.max.x,
        geometry.boundingBox.max.z
      );
      const pointB = new THREE.Vector2(
        geometry.boundingBox.min.x,
        geometry.boundingBox.min.z
      );

      // 計算point距離來當bounding長度
      const distance = pointA.distanceTo(pointB);
      let singleDoor = null;

      // 依據point x,y座標來定義 door bounding位置
      if (pointA.x === pointB.x) {
        const midY = (pointA.y + pointB.y) / 2;
        const centerPos = new THREE.Vector2(pointA.x, midY);
        singleDoor = createSingleDoorBounding(centerPos, distance);
        singleDoor.rotateY(Math.PI / 2);
      } else {
        const midX = (pointA.x + pointB.x) / 2;
        const centerPos = new THREE.Vector2(midX, pointA.y);
        singleDoor = createSingleDoorBounding(centerPos, distance);
      }
      // door bounding 加到 door model children 繼承position scale rotation
      mesh.add(singleDoor);
      singleDoor.position.set(singleDoor.position.x, 0, singleDoor.position.z);
      // re-attach to group parent (change parent but keep position, scale, rotation)
      doorBoundingGroup.attach(singleDoor);
      singleDoor.position.setY(doorBoundingPosY);
    }
  });
  return doorBoundingGroup;
}

function setDoorBoundingAlpha(alpha) {
  for (let i = 0; i < doorBoundingGroup.children.length; i += 1) {
    doorBoundingGroup.children[i].material.opacity = alpha;
  }
}

function setWallBoundingAlpha(alpha) {
  for (let i = 0; i < wallBoundingGroup.children.length; i += 1) {
    wallBoundingGroup.children[i].material.opacity = alpha;
  }
}

function setAllRoomFloorAreaAlpha(alpha) {
  for (let i = 0; i < roomFloorAreaGroup.children.length; i += 1) {
    roomFloorAreaGroup.children[i].material.opacity =
      alpha * roomFloorAreaMaxAlpha;
  }
}

function setAllBoundingAlpha(alpha) {
  setWallBoundingAlpha(alpha);
  setDoorBoundingAlpha(alpha);
  setAllRoomFloorAreaAlpha(alpha);
}

export default {
  createDoorBoundingGroup,
  createWallBoundingGroup,
  createRoomFloorArea,
};
export { setAllBoundingAlpha };
