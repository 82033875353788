import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch } from 'react-router-dom';
import Router from './router';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Router />
    </Switch>
  </BrowserRouter>,
  document.querySelector('#root')
);
console.log(`Mode: ${process.env.REACT_APP_NODE_ENV}`);
// console.log(`build Time : ${VERSION}`);
