const THREE = require('three');
const OBJLoader = require('three-obj-loader');

OBJLoader(THREE);

const loader = new THREE.OBJLoader();

function parseObject(text) {
  return new Promise((resolve, reject) => {
    if (text == null) {
      reject(text);
    }
    const data = loader.parse(text);
    resolve(data);
  });
}

function loadObject(url) {
  return new Promise((resolve, reject) => {
    const onLoad = iObject => resolve(iObject);
    loader.load(url, onLoad, undefined, reject);
  });
}

export default loader;
export { parseObject, loadObject };
