import * as THREE from 'three';
import imageHotspot from 'asset/image/Hotspot.png';
import { loadTexture } from '../loaders/texture-loader';
import Carrier from '../carrier';
import RenderOrder from './mat-manager';

let hotspotTexture;
loadTexture(imageHotspot)
  .then(texture => {
    hotspotTexture = texture;
  })
  .catch(e => {
    console.error(e);
  });
function getManhattanRotation(data) {
  const matrix = new THREE.Matrix4();

  matrix.set(
    data[0][0],
    data[0][1],
    data[0][2],
    0,
    data[1][0],
    data[1][1],
    data[1][2],
    0,
    data[2][0],
    data[2][1],
    data[2][2],
    0,
    0,
    0,
    0,
    1
  );
  const manhattanRotation = new THREE.Euler();
  manhattanRotation.setFromRotationMatrix(matrix);
  return manhattanRotation.y;
}
function createDoor(
  iMesh,
  iTexture,
  iMainroomId,
  cameraHeight,
  manhattanMatrix
) {
  const manhattanRotation = getManhattanRotation(manhattanMatrix);
  const door = new Carrier.DoorCarrier(iMesh, iMainroomId);
  door.setMahattanRotation(manhattanRotation);
  door.setTexture(iTexture);
  door.setCameraHeight(cameraHeight);
  const { mesh } = door;
  mesh.renderOrder = RenderOrder.door;
  mesh.frustumCulled = false;
  return door;
}
function createRoom(
  iMesh,
  iTexture,
  iMainroomId,
  cameraHeight,
  position,
  rotationY,
  layoutPoints,
  manhattanMatrix
) {
  const room = new Carrier.RoomCarrier(iMesh, iMainroomId);
  const manhattanRotation = getManhattanRotation(manhattanMatrix);
  room.setManhattanRotation(manhattanRotation);
  room.setTexture(iTexture);
  room.setCameraHeight(cameraHeight);
  const points = [];
  for (let i = 0; i < layoutPoints.points.length; i += 1) {
    const x = layoutPoints.points[i].xyz[0];
    const z = layoutPoints.points[i].xyz[2];
    points.push(new THREE.Vector2(x, z));
  }
  room.setPoints(points);

  const { mesh, tagLine } = room;

  // align object(room)'s floor with y=0;
  const bbox = new THREE.Box3().setFromObject(mesh);
  const center = new THREE.Vector3();
  const size = new THREE.Vector3();
  bbox.getCenter(center);
  bbox.getSize(size);
  mesh.position.y = -center.y + size.y / 2;

  mesh.position.x = position['0'];
  mesh.position.z = position['1'];
  mesh.rotation.y = rotationY;
  mesh.renderOrder = RenderOrder.room;
  mesh.frustumCulled = false;

  tagLine.renderOrder = RenderOrder.tagLine;
  tagLine.translateX(mesh.position.x);
  tagLine.translateY(mesh.position.y);
  tagLine.translateZ(mesh.position.z);

  return room;
}
function createHotspot(
  position,
  rotateY,
  hotspotId,
  iMainroomId,
  manhattanMatrix,
  parentMesh
) {
  const hotspot = new Carrier.HotspotCarrier(iMainroomId, hotspotId);
  hotspot.setRotateY(rotateY);
  hotspot.setTexture(hotspotTexture);
  const { mesh } = hotspot;
  const manhattanRotation = getManhattanRotation(manhattanMatrix);
  hotspot.setManhattanRotation(manhattanRotation);
  mesh.renderOrder = RenderOrder.hotspot;

  const factorResolveZfighting = 0.001;
  mesh.position.y = -parentMesh.position.y + factorResolveZfighting;
  hotspot.setCameraHeight(parentMesh.position.y - factorResolveZfighting);

  mesh.frustumCulled = false;
  if (position !== undefined) {
    mesh.position.x = position['0'];
    mesh.position.z = position['2'];
  }
  mesh.rotation.x = 0.5 * Math.PI;
  return hotspot;
}

function createAll(hierarchy, labels, roomModels, doorModels, textures) {
  return new Promise(resolve => {
    const allRooms = {};
    const allDoors = {};
    const allHotspots = {};
    const allRoomMesh = new THREE.Group();
    Object.keys(hierarchy).forEach(mainRoomId => {
      const roomMesh = roomModels[mainRoomId].children[0];
      const doorMesh = doorModels[mainRoomId].children[0];
      let relativePos = [0, 0, 0];
      if (hierarchy[mainRoomId].layout.RelativePos != null) {
        relativePos = hierarchy[mainRoomId].layout.RelativePos;
      }
      const hotspotInfo = hierarchy[mainRoomId].hotspot.spot;
      const { rotationY } = hierarchy[mainRoomId].layout;
      const label = hierarchy[mainRoomId].layout.data;
      const texture = textures[mainRoomId];
      const { cameraHeight, layoutPoints, manhattanMatrix } = label;
      const room = createRoom(
        roomMesh,
        texture,
        mainRoomId,
        cameraHeight,
        relativePos,
        rotationY,
        layoutPoints,
        manhattanMatrix
      );
      const door = createDoor(
        doorMesh,
        texture,
        mainRoomId,
        cameraHeight,
        manhattanMatrix
      );
      const hotspots = {};
      hotspotInfo.forEach(hotspotConfig => {
        const { position, rotateY, member } = hotspotConfig;
        const matrix = labels[member].manhattanMatrix;
        const hotspot = createHotspot(
          position,
          rotateY,
          member,
          mainRoomId,
          matrix,
          room.mesh
        );
        hotspots[member] = hotspot;
        room.mesh.add(hotspot.mesh);
      });
      allRooms[mainRoomId] = room;
      allDoors[mainRoomId] = door;
      allHotspots[mainRoomId] = hotspots;
      room.mesh.add(door.mesh);
      allRoomMesh.add(room.mesh);
      allRoomMesh.add(room.tagLine);
    });
    resolve({ allRoomMesh, allRooms, allDoors, allHotspots });
  });
}

export default createAll;
