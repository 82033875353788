import * as THREE from 'three';
import * as matManager from './mat-manager';

const { RenderOrder, areaMat } = matManager;

export default function createAreaMesh(area, group) {
  const shape = new THREE.Shape();

  shape.moveTo(area[0].x, area[0].z);
  for (let i = 1; i < area.length; i += 1) {
    shape.lineTo(area[i].x, area[i].z);
  }

  const geometry = new THREE.ShapeGeometry(shape);
  const mesh = new THREE.Mesh(geometry, areaMat);
  mesh.renderOrder = RenderOrder.area;
  group.add(mesh);
}
