import CubemapShader from './cubemap';
import CubemapShader1x6 from './cubemap1x6';
import CubemapShaderWorldPos from './cubemap-world-position';
import AltasShaderWorldPos from './altas-world-position';

export default {
  CubemapShader,
  CubemapShader1x6,
  CubemapShaderWorldPos,
  AltasShaderWorldPos,
};
