export default {
  SET_BUILDING: 'SET_BUILDING',
  SET_DOLLHOUSE_CUBEMAP: 'SET_DOLLHOUSE_CUBEMAP',
  SET_ROOM_MODEL: 'SET_ROOM_MODEL',
  SET_DOOR_MODEL: 'SET_DOOR_MODEL',
  SET_VIEWBTN: 'SET_VIEWBTN',
  SET_HIERARCHY: 'SET_HIERARCHY',
  SET_LABELS: 'SET_LABELS',
  SET_BUILDINGID: 'SET_BUILDINGID',
  SET_CUBEMAP_URLS: 'SET_CUBEMAP_URLS',
  SET_CUBEMAP_URLS_1X6: 'SET_CUBEMAP_URLS_1X6',
  SET_PANORAMA_IMAGES: 'SET_PANORAMA_IMAGES',
};
