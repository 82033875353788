import zoomBonuce from '../moving-controls/zoomBounce';

function CanvasEvents(
  mouseControls,
  movingController,
  mouseHintController,
  viewerCameraController,
  allHotspotsMesh,
  allRoomMesh,
  allDoorMesh,
  allHotspots
) {
  let selectedHotspot = null;
  const { raycaster } = mouseHintController;
  function onCanvasMouseMove(event) {
    mouseControls.update(event);

    // 走動中不顯示mouse hint point
    if (movingController.isMoving === true) {
      mouseHintController.setMousePointVisible(false);
    } else {
      // 更新滑鼠在場景上標示位置
      mouseHintController.updateMousePoint(mouseControls.mouse, allRoomMesh);
      if (movingController.nowIsTopView === false) {
        // 若raycast找不到可以走的hotspot則不顯示mouse hint point
        const nearestHotspot = mouseHintController.getNearestHotspot(
          mouseControls.mouse,
          allHotspotsMesh.concat(allRoomMesh),
          movingController.destinationHotspot.mesh
        );

        if (nearestHotspot === null || nearestHotspot === undefined) {
          mouseHintController.setMousePointVisible(false);
        } else {
          mouseHintController.setMousePointVisible(true);
        }
      } else {
        mouseHintController.setMousePointVisible(true);
      }
    }

    // 設置raycast
    raycaster.setFromCamera(mouseControls.mouse, viewerCameraController.camera);
    const hotspotinteracts = raycaster.intersectObjects(allHotspotsMesh);
    if (hotspotinteracts.length > 0) {
      if (hotspotinteracts[0].object.objectType === 'hotspot') {
        selectedHotspot = hotspotinteracts[0].object;
        if (!movingController.nowIsTopView) {
          // 鼠標指到hotspot 且hotspot不等於目前站點hotspot
          if (
            selectedHotspot.hotspotId !==
            movingController.destinationHotspot.hotspotId
          ) {
            selectedHotspot.onMouseEnter();
          }
        }
      }
    } else {
      // Raycast指到的非hotspot代表鼠標離開hotspot
      hotspotinteracts.length = 0;
      if (selectedHotspot != null) {
        // 鼠標離開hotspot -> 將上一次指到的hotspot改為原本顏色
        selectedHotspot.onMouseLeave();
        selectedHotspot = null;
      }
    }
  }
  function onCanvasMouseUp(event) {
    // 移動中不判定點擊事件
    if (mouseControls.isDragging(event)) return;
    if (movingController.isMoving === true) return;

    raycaster.setFromCamera(mouseControls.mouse, viewerCameraController.camera);

    let objectInteracts;

    // 點擊目標為hotspot
    if (movingController.nowIsTopView === true) {
      // 目前是topView可點擊為 room + door mesh object
      objectInteracts = raycaster.intersectObjects(
        allRoomMesh.concat(allDoorMesh)
      );
    } else {
      // raycast可以射到房間及hotspot -> 防止點擊到牆後hotspot
      objectInteracts = raycaster.intersectObjects(
        allRoomMesh.concat(allHotspotsMesh)
      );
      if (
        objectInteracts.length > 0 &&
        objectInteracts[0].object.objectType !== 'hotspot'
      ) {
        objectInteracts = [];

        // update mouse point
        mouseHintController.updateMousePoint(mouseControls.mouse, allRoomMesh);
        // get nearestHotspot
        const nearestHotspot = mouseHintController.getNearestHotspot(
          mouseControls.mouse,
          allHotspotsMesh.concat(allRoomMesh),
          movingController.destinationHotspot.mesh
        );
        if (nearestHotspot != null && nearestHotspot !== undefined) {
          const { mainRoomId, hotspotId } = nearestHotspot;
          movingController.hotspot2Hotspot(allHotspots[mainRoomId][hotspotId]);
        } else {
          zoomBonuce(viewerCameraController);
        }
      }
    }

    if (objectInteracts.length > 0) {
      if (movingController.nowIsTopView) {
        const { mainRoomId } = objectInteracts[0].object;
        movingController.top2HotSpot(allHotspots[mainRoomId][mainRoomId]);
      } else {
        const { mainRoomId, hotspotId } = objectInteracts[0].object;
        const hotspot = allHotspots[mainRoomId][hotspotId];
        movingController.hotspot2Hotspot(hotspot);
      }
    }
    if (selectedHotspot != null) {
      selectedHotspot.onMouseLeave();
      selectedHotspot = null;
    }
  }
  function attach(canvas) {
    canvas.addEventListener('mousedown', onCanvasMouseMove);
    canvas.addEventListener('touchstart', onCanvasMouseMove);
    canvas.addEventListener('mousemove', onCanvasMouseMove);
    canvas.addEventListener('touchmove', onCanvasMouseMove);
    canvas.addEventListener('mouseup', onCanvasMouseUp);
    canvas.addEventListener('touchend', onCanvasMouseUp);
  }
  this.attach = attach;
  this.onCanvasMouseMove = onCanvasMouseMove;
  this.onCanvasMouseUp = onCanvasMouseUp;
}
export default CanvasEvents;
