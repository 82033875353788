import * as THREE from 'three';
import store from 'store/3dviewer';
import MovingController from './moving-controls';
import { viewerCameraControls } from './camera/cameraControls';
import ObjectManager from './object-manager';
import MouseEvents from './mouse-events';

function PanoramaViewer(canvas, callback) {
  const scene = new THREE.Scene();

  const viewerCameraController = viewerCameraControls.init(canvas);
  const movingController = new MovingController();
  const mouseEvents = new MouseEvents(viewerCameraController);
  function animate() {
    requestAnimationFrame(animate);
    viewerCameraController.render(scene);
  }

  async function init() {
    const {
      dollHouseCubemap,
      hierarchy,
      labels,
      doorModels,
      roomModels,
    } = store.getState();

    const {
      allRoomMesh,
      allRoomsMeshArray,
      allDoorsMeshArray,
      allHotspotsMeshArray,
      doorBoundingGroup,
      wallBoundingGroup,
      floorplanAreaGroup,
      hotspots,
    } = await ObjectManager.init(
      hierarchy,
      roomModels,
      doorModels,
      labels,
      dollHouseCubemap
    );
    await mouseEvents.init(
      movingController,
      allHotspotsMeshArray,
      allRoomsMeshArray,
      allDoorsMeshArray,
      hotspots,
      canvas
    );
    const { mouseHintController } = mouseEvents;
    // 設置moving controller 基本參數
    movingController.init(viewerCameraController, mouseHintController, scene);

    scene.add(allRoomMesh);
    scene.add(mouseHintController.mousePoint);
    scene.add(movingController.recordHintPointController.recordHintPoint);
    scene.add(doorBoundingGroup);
    scene.add(wallBoundingGroup);
    scene.add(floorplanAreaGroup);
    animate();
    callback();
  }
  init();
}
export default PanoramaViewer;
