const host = 'https://vrcam-dev-cdn.istaging.com/';

export default {
  generateCubemapSource(source) {
    const tPreview = source.replace(
      /panoramas(\/|%2F)/,
      'panoramas/cubemap_previewsmooth_'
    );
    const tCubmap = source.replace(
      /panoramas(\/|%2F)/,
      'panoramas/cubemap_%s_'
    );

    const preview = host + tPreview;
    const cubemap = [
      host + tCubmap.replace('%s', 'r'),
      host + tCubmap.replace('%s', 'l'),
      host + tCubmap.replace('%s', 'u'),
      host + tCubmap.replace('%s', 'd'),
      host + tCubmap.replace('%s', 'f'),
      host + tCubmap.replace('%s', 'b'),
    ];

    return {
      preview,
      cubemap,
    };
  },
};
