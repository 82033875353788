import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Route } from 'react-router-dom';
import { Main } from '../template/pages';

const ViewerScene = props => {
  const { match } = props;
  const { url, length } = match;
  const buildingId = url.substring(1, length);
  return <Main buildingId={buildingId} />;
};
function Router() {
  return (
    <div className="ViewerScene">
      {/* The corresponding component will show here if the current URL matches the path */}
      <Route path="/*" exact component={ViewerScene} />
    </div>
  );
}

ViewerScene.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default Router;
