import React, { Component } from 'react';
import store from 'store/3dviewer';
import './css/roomtypetag.scss';
import { STATE } from 'services/3dviewer/moving-controls';

export default class RoomTag extends Component {
  constructor() {
    super();
    this.state = {
      bottom: '50%',
      right: '50%',
      display: '',
    };
    this.roomtype = '';
    this.onClick = () => {};
    this.updatePosition = this.updatePosition.bind(this);
  }

  componentDidMount() {
    const { room } = this.props;
    const { mainRoomId, mesh } = room;
    const { hierarchy } = store.getState();
    this.roomtype = hierarchy[mainRoomId].info.type;
    const self = this;
    const setPos = () => {
      const sreenCoordinate = room.getTagScreenPosition();
      const screenX = (1 - sreenCoordinate.x) * 100;
      const screenY = (1 - sreenCoordinate.y) * 100;
      self.updatePosition(screenX, screenY);
    };

    mesh.onAfterRender = setPos;

    this.onClick = () => {
      const { viewButton } = store.getState();
      const { goDependOnState } = viewButton;
      goDependOnState(mainRoomId);
    };

    store.subscribe(() => {
      const { viewButton } = store.getState();
      const { viewState } = viewButton;

      if (viewState === STATE.FPVIEW) {
        room.tagVisible = false;
        mesh.onAfterRender = () => {};
        this.hide();
      } else if (!STATE.isMoving) {
        room.tagVisible = true;
        mesh.onAfterRender = setPos;
      }
      this.forceUpdate();
    });
  }

  updatePosition(left, top) {
    this.setState({
      right: `${left.toString()}%`,
      bottom: `${top.toString()}%`,
      display: '',
    });
  }

  hide() {
    this.setState({
      display: 'none',
    });
  }

  render() {
    return (
      <button className="roomTag" style={this.state} onClick={this.onClick}>
        {this.roomtype}
      </button>
    );
  }
}
