import React from 'react';
import Swiper from 'swiper';
import store from 'store/3dviewer';
import './css/panorama-swiper.scss';
import 'swiper/swiper.scss';
import { STATE } from 'services/3dviewer/moving-controls';
import human from 'asset/image/human.svg';
import rectangle from 'asset/image/rectangle.svg';
import floorplan from 'asset/image/floorplan.svg';

export default class PanoramaSwiper extends React.Component {
  constructor() {
    super();
    this.swiper = null;
    this.panoramaImages = null;
    this.items = null;
    this.contents = [];
    this.onWindowResize = this.onWindowResize.bind(this);
    this.forceUpdate = this.forceUpdate.bind(this);
    window.addEventListener('resize', this.onWindowResize);
    this.swiperStyle = { transform: 'translateY(0%)' };
    this.showSwiper = true;
    this.buttonContainerStyle = {};
  }

  componentDidMount() {
    this.swiper = new Swiper('.swiper-container', {
      slidesPerView: 'auto',
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
      roundLengths: true,
      mousewheel: true,
      freeMode: true,
      freeModeMomentum: true,
      freeModeMomentumBounce: false,
      freeModeMomentumVelocityRatio: 0.5,
      preventClicksPropagation: true,
      grabCursor: true,
    });
    this.swiper.update();
    store.subscribe(this.forceUpdate);
  }

  onWindowResize() {
    this.swiper.update();
  }

  renderListItems() {
    const onClickEvent = e => {
      const { viewButton } = store.getState();
      const { goDependOnState } = viewButton;
      goDependOnState(e.target.id);
    };
    const { hierarchy, panoramaImages } = store.getState();
    this.panoramaImages = panoramaImages;
    this.contents = [];
    Object.keys(this.panoramaImages).forEach(id => {
      const roomType = hierarchy[id].info.type;
      this.contents.push(
        <div className="swiper-slide" key={id}>
          <button
            type="button"
            className="panoramas-list-item-container"
            onClick={onClickEvent}
          >
            <img src={this.panoramaImages[id]} alt="room" id={id} />
            <div className="room-type">{roomType}</div>
          </button>
        </div>
      );
    });
    return this.contents;
  }

  renderSwiper() {
    return (
      <div className="panorama-swiper">
        <div className="swiper-container">
          <div className="swiper-wrapper">{this.renderListItems()}</div>
        </div>
      </div>
    );
  }

  renderSwiperButton() {
    const displaySwiper = () => {
      this.showSwiper = !this.showSwiper;
      if (!this.showSwiper) {
        this.swiperStyle = {
          transform: 'translateY(0%)',
        };
      }
      if (this.showSwiper) {
        this.swiperStyle = {
          transform: 'translateY(82%)',
        };
      }
      this.forceUpdate();
    };
    return (
      <div className="button-container" onClick={displaySwiper}>
        <i className="down" />
      </div>
    );
  }

  renderClickButton() {
    const { viewButton } = store.getState();
    const { viewState, goDown, goUp, toOrthographics } = viewButton;

    return (
      <div className="go-button-container">
        <button
          className={viewState === STATE.FPVIEW ? 'hidden' : 'go-button'}
          type="button"
          onClick={goDown}
        >
          <img src={human} alt="human" />
          <i className="icon-walk-view"> </i>
          <span className="buttonText"> &nbsp;行走視角 </span>
        </button>
        <button
          className={viewState === STATE.TOPVIEW ? 'hidden' : 'go-button'}
          type="button"
          onClick={goUp}
        >
          <img src={rectangle} alt="rectangle" />
          <i className="icon-3d-view"> </i>
          <span className="buttonText">&nbsp;3D視角 </span>
        </button>
        <button
          className={viewState === STATE.FLOORPLAN ? 'hidden' : 'go-button'}
          type="button"
          onClick={toOrthographics}
        >
          <img src={floorplan} alt="floorplan" />
          <i className="icon-orthogonal-view"> </i>
          <span className="buttonText">&nbsp;格局圖 </span>
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="swiper-list" style={this.swiperStyle}>
        {this.renderSwiperButton()}
        {this.renderClickButton()}
        {this.renderSwiper()}
      </div>
    );
  }
}
