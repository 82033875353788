import * as THREE from 'three';
import imageMouseHintPoint from 'asset/image/MouseHintPoint.png';
import { loadTexture } from '../loaders/texture-loader';
import RenderOrder from '../object-manager/mat-manager';

function isMobile() {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
}

export default class MouseHintController {
  constructor(viewerCameraController, allHotspots) {
    this.mousePoint = null;
    this.allHotspots = allHotspots;
    // 鼠標位置提示參數
    this.mousePointAlpha = 1; // 透明度 Max = 1
    this.mousePointRadiusSize = 0.01; // 半徑大小
    this.raycaster = new THREE.Raycaster();

    // this.mouseIsDown = false;
    this.initMousePoint();
    this.viewerCameraController = viewerCameraController;
  }

  /**
   * 初始化創建mouse point circle object
   */
  async initMousePoint() {
    const geometry = new THREE.CircleGeometry(this.mousePointRadiusSize, 32);
    const initMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide,
    });
    if (isMobile()) {
      this.mousePoint = new THREE.Mesh();
    } else {
      this.mousePoint = new THREE.Mesh(geometry, initMaterial);
    }
    loadTexture(imageMouseHintPoint)
      .then(texture => {
        const material = new THREE.MeshBasicMaterial({
          map: texture,
          side: THREE.DoubleSide,
          transparent: true,
          opacity: this.mousePointAlpha,
          depthTest: false,
        });
        this.mousePoint.material = material;
      })
      .catch(e => {
        console.error(e);
      });

    this.mousePoint.renderOrder = RenderOrder.mouseHint;
    this.mousePoint.name = 'MousePoint';
    this.mousePoint.visible = false;
  }

  /**
   * 更新mouse point 提示圖案位置及旋轉角度
   * @param {*} mouseSceenPoint
   * @param {*} intersectObjects
   */
  updateMousePoint(mouseSceenPoint, intersectObjects) {
    if (isMobile()) return;
    const coords = mouseSceenPoint;
    this.raycaster.setFromCamera(coords, this.viewerCameraController.camera);

    const objectInteracts = this.raycaster.intersectObjects(intersectObjects);

    if (objectInteracts.length > 0) {
      // position
      let pointWorldPosition = new THREE.Vector3();
      pointWorldPosition = objectInteracts[0].point;
      this.mousePoint.position.set(
        pointWorldPosition.x,
        pointWorldPosition.y,
        pointWorldPosition.z
      );

      // rotation
      const objectFaceNormal = objectInteracts[0].face.normal;
      let objectRotationY = objectInteracts[0].object.rotation.y;

      // get object roation y
      objectRotationY %= Math.PI;

      // normal是x (旋轉狀態與normal是x者相反)
      if (objectFaceNormal.x !== 0) {
        // set position offset(與normal反向offset 以防z-fighting)
        if (objectFaceNormal.x < 0) {
          this.mousePoint.position.x -= 0.001;
        } else {
          this.mousePoint.position.x += 0.001;
        }

        // set rotation
        if (objectRotationY >= Math.PI / 2) {
          // 若object有被旋轉過y 則旋轉z軸
          this.mousePoint.rotation.set(0, 0, Math.PI / 2);
        } else {
          // 若無 則旋轉y軸
          this.mousePoint.rotation.set(0, Math.PI / 2, 0);
        }
      }
      // normal是y
      else if (objectFaceNormal.y !== 0) {
        if (objectFaceNormal.y < 0) {
          this.mousePoint.position.y -= 0.001;
        } else {
          this.mousePoint.position.y += 0.001;
        }

        // 若normal為y 則不考慮object是否被旋轉
        this.mousePoint.rotation.set(Math.PI / 2, 0, 0);
      }
      // normal是z (旋轉狀態與normal是x者相反)
      else if (objectFaceNormal.z !== 0) {
        if (objectFaceNormal.z < 0) {
          this.mousePoint.position.z -= 0.001;
        } else {
          this.mousePoint.position.z += 0.001;
        }

        if (objectRotationY >= Math.PI / 2) {
          // 若object有被旋轉過y 則旋y軸
          this.mousePoint.rotation.set(0, Math.PI / 2, 0);
        } else {
          // 若無 則旋轉z軸
          this.mousePoint.rotation.set(0, 0, Math.PI / 2);
        }
      }
    }
  }

  /**
   * 取得Raycast固定夾角內最近hotspot (不直接點擊hotspot觸發走動)
   * @param {*} mouseSceenPoint
   * @param {*} intersectObjects
   * @param {*} nowHotspot
   */
  getNearestHotspot(mouseSceenPoint, intersectObjects, nowHotspot) {
    const maxAngleBetweenTwoDirection = Math.PI / 6; // raycast取hotspot個別左右兩邊最大夾角
    const { camera } = this.viewerCameraController; // scene camera
    const nearMousePointHotspots = []; // 接近mouse point hotspots (夾角內抓到的hotspot)
    let nearestHotspot = null; // nearMousePointHotspots array中最接近camera的hotspot

    camera.updateMatrix(); // make sure camera's local matrix is updated
    camera.updateMatrixWorld(); // make sure camera's world matrix is updated

    this.raycaster.setFromCamera(mouseSceenPoint, camera);
    const objectIntersectsMouse = this.raycaster.intersectObjects(
      intersectObjects
    );

    // 確認mouse raycast有射到東西
    if (objectIntersectsMouse.length > 0) {
      const pointWorldPosition = new THREE.Vector3();
      pointWorldPosition.copy(objectIntersectsMouse[0].point);
      pointWorldPosition.y = camera.position.y; // raycast方向 camera射到鼠標的"XZ"座標位置 Y軸位置設與camera相同

      const directionCameraToMousePoint = new THREE.Vector3(); // camera射到鼠標direction
      directionCameraToMousePoint
        .subVectors(pointWorldPosition, camera.position)
        .normalize();

      // hotspot raycast
      const directionCameraToHotspotXZ = new THREE.Vector3(); // raycast方向 camera射到hotspot的"XZ"座標位置 Y軸位置設與camera相同 (為了取出與鼠標點擊方向夾角)
      const directionCameraToHotspotXYZ = new THREE.Vector3(); // raycast方向 camera射到hotspot的"XYZ"座標位置 (為了確認是否穿牆)
      const destineObjWorldPos = new THREE.Vector3(); // 暫存hotspot位置
      let objectIntersectsHotspot = [];
      for (let i = 0; i < this.allHotspots.length; i += 1) {
        if (this.allHotspots[i] !== nowHotspot) {
          // 抓取world position
          this.allHotspots[i].getWorldPosition(destineObjWorldPos);

          // 取出攝影機到hotspot raycast方向 (取XYZ)
          directionCameraToHotspotXYZ
            .subVectors(destineObjWorldPos, camera.position)
            .normalize();

          // 取出攝影機到hotspot raycast 水平方向 (只取XZ)
          destineObjWorldPos.y = camera.position.y;
          directionCameraToHotspotXZ
            .subVectors(destineObjWorldPos, camera.position)
            .normalize();

          // raycast到hotspot
          this.raycaster.set(camera.position, directionCameraToHotspotXYZ);
          objectIntersectsHotspot = this.raycaster.intersectObjects(
            intersectObjects
          );

          if (objectIntersectsHotspot.length > 0) {
            // 若射到非hotspot 代表該視角內不能走到該hotspot
            if (objectIntersectsHotspot[0].object.objectType === 'hotspot') {
              // three-js angleTo can't handle zero vector
              if (
                !(
                  directionCameraToMousePoint.equals(
                    new THREE.Vector3(0, 0, 0)
                  ) ||
                  directionCameraToHotspotXZ.equals(new THREE.Vector3(0, 0, 0))
                )
              ) {
                const angle = directionCameraToMousePoint.angleTo(
                  directionCameraToHotspotXZ
                );

                // 角度那抓取hotspot
                if (angle <= maxAngleBetweenTwoDirection) {
                  nearMousePointHotspots.push(this.allHotspots[i]);
                }
              }
            }
          }
        }
      }

      // 取在 nearMousePointHotspots 中離camera最近的hotspot
      let tempShortestDistance = 10000;
      for (let i = 0; i < nearMousePointHotspots.length; i += 1) {
        const tempHotspotPos = new THREE.Vector3();
        let tempDistanceToHotspot = null;
        nearMousePointHotspots[i].getWorldPosition(tempHotspotPos);
        tempDistanceToHotspot = tempHotspotPos.distanceTo(camera.position);

        if (tempDistanceToHotspot < tempShortestDistance) {
          tempShortestDistance = tempDistanceToHotspot;
          nearestHotspot = nearMousePointHotspots[i];
        }
      }

      if (nearestHotspot != null && nearestHotspot !== undefined) {
        return nearestHotspot;
      }
      return null;
    }
    return null;
  }

  // 設置mouse point circle visible
  setMousePointVisible(visible) {
    this.mousePoint.visible = visible;
  }
}
