import * as THREE from 'three';

const loader = new THREE.CubeTextureLoader();

function loadCubeTexture(source) {
  return new Promise((resolve, reject) => {
    const onLoad = iTexture => resolve(iTexture);
    loader.load(source, onLoad, undefined, reject);
  });
}

export default loader;
export { loadCubeTexture };
