import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import APIService from 'services/api/3dviewer';
import store from 'store/3dviewer';
import actions from 'store/3dviewer/actions';
import dataFunctions from 'services/3dviewer/utils/datafunctions';
import CubemapHelper from 'asset/js/cubemap-helper';
import ImageService from 'services/image';
import { loadTexture } from 'services/3dviewer/loaders/texture-loader';
import { parseObject } from 'services/3dviewer/loaders/object-loader';
import ProgressBar from './progressbar';
import '../css/launcher.scss';

export default class Launcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedArr: [],
      total: 100,
    };
    this.roomModelData = {};
    this.doorModelData = {};
    this.dollHouseCubemapsData = {};
    this.cubemapUrls = {};
    this.cubemapUrls1x6 = {};
    this.buildingId = _.get(store.getState(), 'buildingId');
    this.currentbuilding = _.get(store.getState(), 'building');
    this.panoramaImages = {};
  }

  componentDidMount() {
    this.init();
  }

  get loaded() {
    const { loadedArr } = this.state;
    return _.sum(loadedArr);
  }

  async init() {
    const hierarchy = this.fetchHierarchy();
    const labels = this.fetchLabels();
    store.dispatch(actions.setHierarchy(hierarchy));
    store.dispatch(actions.setLabels(labels));
    await this.preload();
    store.dispatch(actions.setDoorModel(this.doorModelData));
    store.dispatch(actions.setRoomModel(this.roomModelData));
    store.dispatch(actions.setDollhouseCubemap(this.dollHouseCubemapsData));
    store.dispatch(actions.setCubemapUrls(this.cubemapUrls));
    store.dispatch(actions.setCubemapUrls1x6(this.cubemapUrls1x6));
    store.dispatch(actions.setPanoramaImages(this.panoramaImages));
    const { onReady } = this.props;
    onReady();
  }

  async preload() {
    const { group } = store.getState().building;
    // prepare source of all 1x6Cubemaps
    const panoramasData = await APIService.fetchPanoramas(this.buildingId);
    const mainRoomData = this.fetchMainRoomData(panoramasData);
    const cubemapUrls1x6 = [];
    const panoramaImagesUrls = [];

    mainRoomData.forEach(panorama => {
      const { objectId, thumbnail } = panorama;
      this.panoramaImages[objectId] = thumbnail;
      panoramaImagesUrls.push({
        objectId,
        url: thumbnail,
        type: 'panorama',
      });
    });
    panoramasData.panoramas.forEach(panorama => {
      const { cubemapFilePath, objectId } = panorama;
      const { preview, cubemap } = CubemapHelper.generateCubemapSource(
        cubemapFilePath
      );
      cubemapUrls1x6.push({
        objectId,
        url: preview,
        type: 'cubemaps',
      });
      this.cubemapUrls[objectId] = cubemap;
      this.cubemapUrls1x6[objectId] = preview;
    });
    // prepare source of models
    const mainRoomsId = dataFunctions.getMainRoomId(group);
    const roomModels = [];
    const doorModels = [];
    mainRoomsId.forEach(id => {
      roomModels.push({
        objectId: id,
        url: null,
        type: 'layout_noDoor',
      });
      doorModels.push({
        objectId: id,
        url: null,
        type: 'layout_door',
      });
    });
    // start preload
    const queue = [];
    const source = _.concat(
      cubemapUrls1x6,
      roomModels,
      doorModels,
      panoramaImagesUrls
    );
    this.setState({ total: 100 * source.length });
    source.forEach((object, index) => {
      const { objectId, url, type } = object;
      if (type === 'cubemaps' || type === 'panorama') {
        const promise = ImageService.load(url, event => {
          const { total, loaded } = event;
          const { loadedArr } = this.state;
          const precent = (100 * loaded) / total;
          const arrClone = loadedArr.slice();
          arrClone[index] = precent;
          this.setState({ loadedArr: arrClone });
        })
          .then(() => {
            // generate texture by three.js textureloader.
            if (type === 'cubemaps') {
              loadTexture(url).then(texture => {
                const textureObject = texture;
                textureObject.name = objectId;
                const mainRoomId = objectId;
                this.dollHouseCubemapsData[mainRoomId] = textureObject;
              });
            } else if (type === 'panorama') {
              this.panoramaImages[objectId] = url;
            }
          })
          .catch(error => {
            console.log(error);
          });
        queue.push(promise);
      } else {
        const promise = APIService.fetchModel(
          objectId,
          this.buildingId,
          type,
          event => {
            const { total, loaded } = event;
            const precent = (100 * loaded) / total;
            const { loadedArr } = this.state;
            const arrClone = loadedArr.slice();
            arrClone[index] = precent;
            this.setState({ loadedArr: arrClone });
          }
        )
          .then(async res => {
            // generate model as group by three.js objLoader.
            const model = await parseObject(res);
            // const id = objId2IdTable[objectId];
            if (type === 'layout_door') {
              // save to an object for later storage.
              this.doorModelData[objectId] = model; // {'id:model'}.
            } else if (type === 'layout_noDoor') {
              this.roomModelData[objectId] = model; // {'id:model'}.
            }
          })
          .catch(error => {
            console.log(error);
          });
        queue.push(promise);
      }
    });
    return Promise.all(queue);
  }

  fetchMainRoomData(panoramasData) {
    const { group } = this.currentbuilding;
    const mainRoomData = [];
    group.forEach(roomData => {
      const mainRoomId = _.get(roomData.layout, 'mainroom');
      panoramasData.panoramas.forEach(panorama => {
        const { objectId } = panorama;
        if (objectId === mainRoomId) {
          mainRoomData.push(panorama);
        }
      });
    });
    return mainRoomData;
  }

  fetchHierarchy() {
    const { group } = this.currentbuilding;
    const hierarchy = {};
    group.forEach(roomData => {
      const mainRoomId = _.get(roomData.layout, 'mainroom');
      hierarchy[mainRoomId] = roomData;
    });
    return hierarchy;
  }

  fetchLabels() {
    const labels = {};
    const { panoramas } = this.currentbuilding;
    Object.keys(panoramas).forEach(objectId => {
      labels[objectId] = panoramas[objectId];
    });
    return labels;
  }

  render() {
    const { total } = this.state;
    return (
      <div className="launcher">
        <div className="launcher-container">
          <ProgressBar value={this.loaded} max={total} />
        </div>
      </div>
    );
  }
}

Launcher.propTypes = {
  onReady: PropTypes.func.isRequired,
};
