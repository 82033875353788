import { combineReducers } from 'redux';
import types from './mutation-types';

function building(iBuilding = null, action) {
  switch (action.type) {
    case types.SET_BUILDING:
      return action.building;
    default:
      return iBuilding;
  }
}
function dollHouseCubemap(dollhouseCubemap = null, action) {
  switch (action.type) {
    case types.SET_DOLLHOUSE_CUBEMAP:
      return action.dollhouseCubemap;
    default:
      return dollhouseCubemap;
  }
}
function roomModels(roomModel = null, action) {
  switch (action.type) {
    case types.SET_ROOM_MODEL:
      return action.roomModel;
    default:
      return roomModel;
  }
}
function doorModels(doorModel = null, action) {
  switch (action.type) {
    case types.SET_DOOR_MODEL:
      return action.doorModel;
    default:
      return doorModel;
  }
}
function viewButton(info = null, action) {
  switch (action.type) {
    case types.SET_VIEWBTN:
      return action.info;
    default:
      return info;
  }
}
function labels(iLabels = null, action) {
  switch (action.type) {
    case types.SET_LABELS:
      return action.labels;
    default:
      return iLabels;
  }
}
function hierarchy(iHierarchy = null, action) {
  switch (action.type) {
    case types.SET_HIERARCHY:
      return action.hierarchy;
    default:
      return iHierarchy;
  }
}
function buildingId(iBuildingId = null, action) {
  switch (action.type) {
    case types.SET_BUILDINGID:
      return action.buildingId;
    default:
      return iBuildingId;
  }
}
function cubemapUrls(urls = null, action) {
  switch (action.type) {
    case types.SET_CUBEMAP_URLS:
      return action.urls;
    default:
      return urls;
  }
}
function cubemapUrls1x6(urls = null, action) {
  switch (action.type) {
    case types.SET_CUBEMAP_URLS_1X6:
      return action.urls;
    default:
      return urls;
  }
}
function panoramaImages(images = null, action) {
  switch (action.type) {
    case types.SET_PANORAMA_IMAGES:
      return action.images;
    default:
      return images;
  }
}
const todoApp = combineReducers({
  building,
  panoramaImages,
  dollHouseCubemap,
  doorModels,
  roomModels,
  viewButton,
  labels,
  hierarchy,
  buildingId,
  cubemapUrls,
  cubemapUrls1x6,
});

export default todoApp;
