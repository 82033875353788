export default `
varying vec3 modelPosition;
    
uniform samplerCube tEquirect;
uniform float rotation;
uniform float alpha;
uniform float x;
uniform float z;
uniform float y;

void main()	{	

    vec3 point = modelPosition;

    point.x = point.x - x;
    point.y = point.y - y;
    point.z = point.z + z;

    float inverRot = -rotation;
    mat4 rotationMat = mat4(cos(inverRot), 0.0, -sin(inverRot), 0.0,  
                            0.0, 1.0, 0.0, 0.0, 
                            sin(inverRot), 0.0, cos(inverRot), 0.0,  
                            0.0, 0.0, 0.0, 1.0);

    vec3 modelVertex = (vec4(point,1.0)*rotationMat).xyz;
    gl_FragColor =  vec4(textureCube(tEquirect, modelVertex).xyz, alpha);
     
}
`;
