import React, { Component } from 'react';
import logo from 'asset/image/logo.png';
import PanoramaView from 'services/3dviewer';
import PropTypes from 'prop-types';
import ObjectManager from 'services/3dviewer/object-manager';
import ViewButton from './viewButton';
import FloorPlanCanvas from './floorplan-canvas';
import PanoramaSwiper from './panorama-swiper';
import RoomTag from './roomtag';

export default class Viewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewLoadCompleted: false,
    };
  }

  async componentDidMount() {
    const { onReady } = this.props;
    PanoramaView(this.canvas, () => {
      this.setState({ viewLoadCompleted: true });
      onReady();
    });
  }

  render() {
    const LogoStyle = {
      position: 'absolute',
      top: '20px',
      left: '20px',
      width: '80px',
      zindex: '100',
    };
    const viewerBlockStlye = {
      width: '100vw',
      height: '100vh',
    };
    const CanvaStyle = {
      width: '100%',
      height: '100%',
      position: 'absolute',
      bottom: '0',
    };
    const { viewLoadCompleted } = this.state;

    const roomTags = [];
    if (viewLoadCompleted === true) {
      const { rooms } = ObjectManager.getAll();
      const roomName = Object.keys(rooms);
      roomName.forEach(id => {
        roomTags.push(<RoomTag room={rooms[id]} key={id} />);
      });
    }

    return (
      <div className="viewerContainer" style={viewerBlockStlye}>
        <img src={logo} style={LogoStyle} alt="iStaging" />
        <canvas
          style={CanvaStyle}
          ref={ref => {
            this.canvas = ref;
          }}
          id="webGL Canvas"
        />
        {viewLoadCompleted === true ? roomTags : null}
        {viewLoadCompleted === true ? <ViewButton /> : null}
        {viewLoadCompleted === true ? <FloorPlanCanvas /> : null}
        {viewLoadCompleted === true ? <PanoramaSwiper /> : null}
      </div>
    );
  }
}

Viewer.propTypes = {
  onReady: PropTypes.func.isRequired,
};
