import * as THREE from 'three';
import Shaders from 'shader';

function DoorCarrier(iMesh, iMainroomId) {
  if (!(iMesh && iMainroomId)) {
    console.log('Mesh or Mainroom is undefined');
    return;
  }
  let texture = null;
  const cameraHeight = null;
  const textureId = null;
  const mahattanRotation = 0;
  const mesh = iMesh;
  const mainRoomId = iMainroomId;
  const CubemapShader1x6 = new Shaders.CubemapShader1x6();
  const material = new THREE.ShaderMaterial({
    fragmentShader: CubemapShader1x6.fragmentShader,
    vertexShader: CubemapShader1x6.vertexShader,
    uniforms: CubemapShader1x6.uniforms,
    depthTest: true,
    transparent: true,
  });

  function setTexture(iTexture, position = new THREE.Vector3(), rotateY = 0) {
    this.textureId = iTexture.name;
    texture = iTexture;
    texture.generateMipmaps = false;
    texture.minFilter = THREE.NearestFilter;
    texture.magFilter = THREE.NearestFilter;
    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;
    material.uniforms.tCube.value = texture;
    material.uniforms.rotation.value = -THREE.Math.degToRad(
      rotateY + THREE.Math.radToDeg(this.mahattanRotation)
    );
    material.uniforms.x.value = position.x;
    material.uniforms.y.value = position.y;
    material.uniforms.z.value = position.z;
    mesh.material = material;
  }
  function getOpacity() {
    return material.uniforms.opacity.value;
  }
  function setOpacity(opacity) {
    material.uniforms.opacity.value = opacity;
  }
  function setCameraHeight(height) {
    this.cameraHeight = height;
  }
  function destroy() {
    material.dispose();
    if (texture) texture.dispose();
  }
  function setMahattanRotation(iMahattanRotation) {
    this.mahattanRotation = iMahattanRotation;
  }
  /** public */
  // getters
  this.mesh = mesh;
  this.mainRoomId = mainRoomId;
  this.cameraHeight = cameraHeight;
  this.textureId = textureId;
  this.mahattanRotation = mahattanRotation;
  // methods
  this.setTexture = setTexture;
  this.destroy = destroy;
  this.setCameraHeight = setCameraHeight;
  this.setOpacity = setOpacity;
  this.setMahattanRotation = setMahattanRotation;

  Object.defineProperty(this, 'mesh', {
    value: mesh,
    enumerable: true,
  });
  Object.defineProperty(this, 'opacity', {
    get: getOpacity,
    set: setOpacity,
  });
  Object.defineProperty(this, 'cameraHeight', {
    value: this.cameraHeight,
    enumerable: true,
  });
  Object.defineProperty(this, 'mahattanRotation', {
    value: this.mahattanRotation,
    enumerable: true,
  });
  Object.defineProperty(mesh, 'mainRoomId', {
    value: this.mainRoomId,
    enumerable: true,
  });
  Object.defineProperty(mesh, 'objectType', {
    value: 'door',
    enumerable: true,
  });
}

export default DoorCarrier;
