import anime from 'animejs/lib/anime.es';
import * as THREE from 'three';

function fovEasing(t) {
  const time = t - 1;
  return time * time * time + 1;
}

const duration = 800;

const clip = (cameraControls, targetFovDegree, dstPolarAngle) => {
  const srcfov = cameraControls.fov;
  const dstfov = (targetFovDegree / 180) * Math.PI;

  const initialScreenHeight = cameraControls.screenHeight;

  // 控anime.js開始動畫
  const timeline = anime.timeline({
    autoplay: false,
    duration,
  });

  cameraControls.setTopViewLimit(cameraControls.getLookAtCenter());
  timeline.add({
    targets: cameraControls,
    polarAngle: [0, dstPolarAngle],
    easing: 'linear',
  });

  timeline.add(
    {
      update(anim) {
        const percentage = fovEasing(anim.progress / 100);

        const srcCenter = cameraControls.getLookAtCenter();
        const dir = new THREE.Vector3().subVectors(
          cameraControls.getCamerPosition(),
          cameraControls.getLookAtCenter()
        );
        dir.normalize();

        const calFov = (1 - percentage) * srcfov + percentage * dstfov;

        const distance = (1 / Math.tan(calFov / 2)) * (initialScreenHeight / 2);
        const position = dir.clone().multiplyScalar(distance);
        const camControl = cameraControls;
        cameraControls.setCamerPos(
          new THREE.Vector3().addVectors(srcCenter, position),
          false
        );
        camControl.fov = calFov;
      },
    },
    0
  );

  timeline.play();

  return timeline.finished;
};

export default clip;
