import types from './mutation-types';

export default {
  setBuilding(building) {
    return { type: types.SET_BUILDING, building };
  },
  setDollhouseCubemap(dollhouseCubemap) {
    return { type: types.SET_DOLLHOUSE_CUBEMAP, dollhouseCubemap };
  },
  setRoomModel(roomModel) {
    return { type: types.SET_ROOM_MODEL, roomModel };
  },
  setDoorModel(doorModel) {
    return { type: types.SET_DOOR_MODEL, doorModel };
  },
  setViewButton(info) {
    return { type: types.SET_VIEWBTN, info };
  },
  setHierarchy(hierarchy) {
    return { type: types.SET_HIERARCHY, hierarchy };
  },
  setLabels(labels) {
    return { type: types.SET_LABELS, labels };
  },
  setBuildingId(buildingId) {
    return { type: types.SET_BUILDINGID, buildingId };
  },
  setCubemapUrls(urls) {
    return { type: types.SET_CUBEMAP_URLS, urls };
  },
  setCubemapUrls1x6(urls) {
    return { type: types.SET_CUBEMAP_URLS_1X6, urls };
  },
  setPanoramaImages(images) {
    return { type: types.SET_PANORAMA_IMAGES, images };
  },
};
