import React from 'react';
import PropTypes from 'prop-types';
import store from 'store/3dviewer/index';
import APIService from 'services/api/3dviewer';
import actions from 'store/3dviewer/actions';
import ThreeDViewer from 'component/3dviewer/';
import '../css/main.scss';

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldViewerShow: false,
      shouldErrorShow: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    const res = await this.fetchBuliding();
    const { data } = res;
    if (data !== undefined) {
      const { buildingId } = this.props;
      store.dispatch(actions.setBuildingId(buildingId));
      store.dispatch(actions.setBuilding(data));
      this.setState({ shouldViewerShow: true });
    } else {
      this.setState({ shouldErrorShow: true });
    }
  }

  fetchBuliding() {
    const { buildingId } = this.props;
    return APIService.fetchBuilding(buildingId);
  }

  render() {
    const { buildingId } = this.props;
    const { shouldErrorShow, shouldViewerShow } = this.state;
    return (
      <div>
        {shouldViewerShow === true ? (
          <ThreeDViewer buildingId={buildingId} />
        ) : null}
        {shouldErrorShow === true ? (
          <div className="errorLog">Building Not Found.</div>
        ) : null}
      </div>
    );
  }
}

Main.propTypes = {
  buildingId: PropTypes.string.isRequired,
};
