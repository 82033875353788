import * as THREE from 'three';
import * as Data from './format-parser';
import ObjectManager from '../object-manager';
import { viewerCameraControls } from '../camera/cameraControls';
import * as iconSetting from './icons';

const {
  cameraMesh,
  createAreaMesh,
  createDoorMesh,
  createWallMesh,
} = iconSetting;

function calculateFloorplanaspect(group) {
  const bbox = new THREE.Box3().setFromObject(group);
  const size = new THREE.Vector3();
  bbox.getSize(size);
  return size.z / size.x;
}

export default function FloorPlan2D(canvas, rawdata) {
  const renderer = new THREE.WebGLRenderer({
    canvas,
    alpha: true,
    antialias: true,
  });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setClearColor(0x000000, 0.3);

  const camera = new THREE.OrthographicCamera(
    -1 / 2,
    1 / 2,
    1 / 2,
    -1 / 2,
    0.01,
    1000
  );
  camera.position.setY(1);
  camera.lookAt(0, 0, 0);

  const scene = new THREE.Scene();
  scene.add(cameraMesh);

  // init normalize Group
  const group = new THREE.Group();
  scene.add(group);
  group.rotateX(-Math.PI / 2);

  // parse data format
  const verticesDict = Data.extraVertices(rawdata);
  const areas = Data.extraArea(rawdata, verticesDict);
  const lines = Data.extraLines(rawdata, verticesDict);
  const holes = Data.extraholes(rawdata, verticesDict);

  // create areas
  const areaKeys = Object.keys(areas);
  areaKeys.forEach(key => {
    if (areas[key].length < 3) return;
    createAreaMesh(areas[key], group);
  });

  // create lines
  const lineKeys = Object.keys(lines);
  lineKeys.forEach(key => {
    const { start, end } = lines[key];
    createWallMesh(start, end, group);
  });

  // normalize objs in group
  ObjectManager.normalizeGroupAndSetCenter(group);

  // create door
  const holeKeys = Object.keys(holes);
  holeKeys.forEach(key => {
    createDoorMesh(holes[key], group);
  });

  const aspect = calculateFloorplanaspect(group);
  function updateViewPort() {
    renderer.setSize(canvas.clientHeight, canvas.clientWidth, false);
    const rendererAspect = canvas.clientHeight / canvas.clientWidth;

    let right = 1.1 / 2;
    let top = 1.1 / 2;

    if (aspect > 1) {
      // 平面圖y=1為base,對x軸還原比例
      if (right / rendererAspect > 1 / aspect) {
        right /= rendererAspect;
      }
      // 還原比例導致clip,改對y軸做還原
      else {
        top *= rendererAspect;
      }
    } else if (top * rendererAspect > aspect) {
      // 平面圖x=1為base,對y軸還原比例
      top *= rendererAspect;
    } else {
      // 還原比例導致clip,改對x軸做還原
      right /= rendererAspect;
    }

    camera.right = right;
    camera.left = -right;
    camera.top = top;
    camera.bottom = -top;

    camera.aspect = canvas.clientWidth / canvas.clientHeight;
    camera.updateProjectionMatrix();
    renderer.render(scene, camera);
  }

  function setCameraIcon(value) {
    cameraMesh.visible = value;
  }

  function render() {
    // set Camera ICON
    cameraMesh.position.copy(viewerCameraControls.getCamerPosition());
    cameraMesh.rotation.y =
      viewerCameraControls.azimuthAngle > 0
        ? viewerCameraControls.azimuthAngle
        : viewerCameraControls.azimuthAngle + 2 * Math.PI;

    renderer.render(scene, camera);
  }

  function animate() {
    requestAnimationFrame(animate);
    render();
  }
  animate();
  window.addEventListener('resize', updateViewPort);
  return {
    updateViewPort,
    setCameraIcon,
  };
}
