import * as THREE from 'three';
import * as matManager from './mat-manager';

const {
  RenderOrder,
  lineWidth,
  doorMat,
  doorThickness,
  arcMaterial,
  hideWallMat,
} = matManager;

export default function createDoorMesh(data, group) {
  const doorIcon = new THREE.Group();
  const { pos, rotation, width, flipOrizzontal } = data;
  // 橫線1
  const lineGeo = new THREE.PlaneGeometry(width, doorThickness, 32);
  const horziLine1 = new THREE.Mesh(lineGeo, doorMat);
  doorIcon.add(horziLine1);
  horziLine1.translateY(-lineWidth / 4);

  // 橫線2
  const horziLine2 = new THREE.Mesh(lineGeo, doorMat);
  doorIcon.add(horziLine2);
  horziLine2.translateY(lineWidth / 4);

  // 直線
  const vertiGeo = new THREE.PlaneGeometry(width, lineWidth * 0.1, 32);
  const vertiDoor = new THREE.Mesh(vertiGeo, doorMat);
  vertiDoor.rotateOnAxis(new THREE.Vector3(0, 0, 1), Math.PI / 2);
  vertiDoor.translateX(width / 2);
  vertiDoor.translateY((width / 2) * (flipOrizzontal ? -1 : 1));
  doorIcon.add(vertiDoor);

  // 弧線
  const curve = new THREE.EllipseCurve(
    flipOrizzontal ? width / 2 : -width / 2,
    0, // ax, aY
    width,
    width, // xRadius, yRadius
    flipOrizzontal ? Math.PI / 2 : 0,
    flipOrizzontal ? Math.PI : Math.PI / 2, // aStartAngle, aEndAngle
    false // aClockwise
  );

  const points = curve.getSpacedPoints(100);
  const geometryArc = new THREE.Geometry().setFromPoints(points);
  const line = new THREE.Line(geometryArc, arcMaterial);
  doorIcon.add(line);

  doorIcon.position.set(pos.x, pos.z, 0);
  doorIcon.rotation.z = rotation;
  doorIcon.renderOrder = RenderOrder.door;
  group.add(doorIcon);

  // 挖洞
  const hideGeo = new THREE.PlaneGeometry(width, lineWidth, 32);
  const hideMesh = new THREE.Mesh(hideGeo, hideWallMat);
  hideMesh.renderOrder = RenderOrder.holeOnWall;
  hideMesh.position.set(pos.x, pos.z, 1);
  hideMesh.rotation.z = rotation;
  group.add(hideMesh);
}
